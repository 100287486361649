<template lang="pug">
  .container
    img(class="mv_okome" src="https://cdn.taberutokurasuto.com/image/events/okome/main_2024.jpg" alt="お米特集" width="100%" height="auto")
    .top
      .text-center
        .okome_heading_bg
        span お気に入り見つかる！
        h1.heading-1.mb-md こだわりの新米2024
        .lead
          p
            | いよいよ今年も新米の季節がやってきました！
            | いつもおいしいお米ですが、やはり新米は特別。
            | お米の瑞々しさと、炊き立ての豊かな香り、そして噛むたびに口いっぱいに広がる甘みと旨み。
            | 幸せの味わいを安心しておいしくいただいてください。
            | 新米、順次公開予定！
      .index
        .title
          p 目次
        .links
          a(href="#kind") 種類別でみる
          a(href="#chart") お米の味わいの特徴
          a(href="#breed") 品種別でみる
          a(href="#banner") 今すぐ届くお米
          a(href="#side") ご飯のお供
      .button-block
        ButtonBase(:href="`/shops/items/category/71078051/?order=popular&stock=instock`") すべてのお米を見る
    //.coupon
    //  img(src="https://cdn.taberutokurasuto.com/image/events/okome/okome_coupon.png" alt="送料半額クーポン")
    .kind(id="kind")
      h2 種類別でみる
      .other
        .column
          a(:href="`/shops/items/category/2421961051/?order=popular&stock=instock`")
            .item
              .image
                img(src="https://cdn.taberutokurasuto.com/image/events/okome/kind_hakumai.png" alt="白米・分つき米・胚芽米")
              .info
                p.title 白米・分つき米・胚芽米
                p.text 白く透き通った美味しく美しい白米。食べやすく消化効率も良い。胚芽米、分つき米などの食べ方も。
                .link
                  a(:href="`/shops/items/category/2421961051/?order=popular&stock=instock`") 商品を見る
          a(:href="`/shops/items/category/3958304051/?order=popular&stock=instock`")
            .item
              .image
                img(src="https://cdn.taberutokurasuto.com/image/events/okome/kind_genmai.png" alt="玄米")
              .info
                p.title 玄米
                p.text 糠や胚芽をそのまま残す玄米。栄養を豊富に含んでいます。歯応えがあって噛むほどに美味しい。
                .link
                  a(:href="`/shops/items/category/3958304051/?order=popular&stock=instock`") 商品を見る
          a(:href="`/shops/items/category/2421968051/?order=popular&stock=instock`")
            .item
              .image
                img(src="https://cdn.taberutokurasuto.com/image/events/okome/kind_kodaimai.png" alt="古代米")
              .info
                p.title 古代米
                p.text 稲の原種の特徴を色濃く残している古代米。品種改良されていないので生命力が強く、栄養も豊富。
                .link
                  a(:href="`/shops/items/category/2421968051/?order=popular&stock=instock`") 商品を見る
          a(:href="`/shops/items/category/71083051/?order=popular&stock=instock`")
            .item
              .image
                img(src="https://cdn.taberutokurasuto.com/image/events/okome/kind_mochigome.png" alt="もち米")
              .info
                p.title もち米
                p.text もちもちとした食感。冷めても硬くなりにくい。お赤飯、おこわ、お餅などに。
                .link
                  a(:href="`/shops/items/category/71083051/?order=popular&stock=instock`") 商品を見る
      .button-block
        ButtonBase(:href="`/shops/items/category/71078051/?order=popular&stock=instock`") すべてのお米を見る
    .chart(id="chart")
      h2 お米の味わいの特徴
      img(src="https://cdn.taberutokurasuto.com/image/events/okome/chart_2024.png" alt="お米の味わいの特徴")
    .breed(id="breed")
      h2 品種別でみる
      .breed-wrapper(v-if=("itemGroupsKoshihikari.length > 0"))
        h3 コシヒカリ
        p 大粒でもっちりとした粘り。口の中に広がる強い甘みや旨み。ふっくら柔らかく、艶がある。
        ItemCardList
          .item.lg(v-for="(itemGroup, index) in itemGroupsKoshihikari" :key="itemGroup.id")
            ItemCard(:itemGroup="itemGroup")
        .button-block
          ButtonBase(:href="`/shops/items/category/23081410027/?order=popular&stock=instock`") コシヒカリをすべて見る
      .breed-wrapper(v-if=("itemGroupsHinohikari.length > 0"))
        h3 ヒノヒカリ
        p 小粒で丸みがある。どんなおかずとも相性良し。甘く、弾力があり適度にふんわりもちもち。
        ItemCardList
          .item.lg(v-for="(itemGroup, index) in itemGroupsHinohikari" :key="itemGroup.id")
            ItemCard(:itemGroup="itemGroup")
        .button-block
          ButtonBase(:href="`/shops/items/category/23081410028/?order=popular&stock=instock`") ヒノヒカリをすべて見る
      .breed-wrapper(v-if=("itemGroupsSasanishiki.length > 0"))
        h3 ササニシキ
        p 粘りは少なく、ほぐれやすい。噛むほどに柔らかい甘み。さっぱりとしていて飽きがこない。
        ItemCardList
          .item.lg(v-for="(itemGroup, index) in itemGroupsSasanishiki" :key="itemGroup.id")
            ItemCard(:itemGroup="itemGroup")
        .button-block
          ButtonBase(:href="`/shops/items/category/23081410035/?order=popular&stock=instock`") ササニシキをすべて見る
      .other
        .column
          a(:href="`/shops/items/category/23081410029/?order=popular&stock=instock`")
            .item
              .image
                img(src="https://cdn.taberutokurasuto.com/image/events/okome/breed_nikomaru.png" alt="にこまる")
              .info
                p.title にこまる
                p.text 大粒で艶が良く粘りが強い。粒ぞろいも良くふっくらモチモチした食感。冷めても硬くなりにくい。
                .link
                  a(:href="`/shops/items/category/23081410029/?order=popular&stock=instock`") 商品を見る
          a(:href="`/shops/items/category/23081410030/?order=popular&stock=instock`")
            .item
              .image
                img(src="https://cdn.taberutokurasuto.com/image/events/okome/breed_isehikari.png" alt="イセヒカリ")
              .info
                p.title イセヒカリ
                p.text 大粒で力強く、しっかりとした味の濃さ。さっぱりとした美味しさ。冷めても味が落ちない。
                .link
                  a(:href="`/shops/items/category/23081410030/?order=popular&stock=instock`") 商品を見る
          a(:href="`/shops/items/category/23090813039/?order=popular&stock=instock`")
            .item
              .image
                img(src="https://cdn.taberutokurasuto.com/image/events/okome/breed_kamichikara.png" alt="神力")
              .info
                p.title 神力
                p.text 粒はしっかりとしていて硬め。粘りはほぼない。甘すぎずもちもちしすぎず、食べやすく頼もしい。
                .link
                  a(:href="`/shops/items/category/23090813039/?order=popular&stock=instock`") 商品を見る
          a(:href="`/shops/items/category/23081410032/?order=popular&stock=instock`")
            .item
              .image
                img(src="https://cdn.taberutokurasuto.com/image/events/okome/breed_asahi.png" alt="朝日")
              .info
                p.title 朝日
                p.text 大粒でふっくらとした粘り。あっさりした味で噛むほどに奥ゆかしい旨味。冷めても味が落ちない。
                .link
                  a(:href="`/shops/items/category/23081410032/?order=popular&stock=instock`") 商品を見る
          a(:href="`/shops/items/category/23081410033/?order=popular&stock=instock`")
            .item
              .image
                img(src="https://cdn.taberutokurasuto.com/image/events/okome/breed_nourin22go.png" alt="農林22号")
              .info
                p.title 農林22号
                p.text ツヤがあり、程よい粘りとあっさりした食感。噛むほどに甘みが増す。冷めても美味しく味が落ちない。
                .link
                  a(:href="`/shops/items/category/23081410033/?order=popular&stock=instock`") 商品を見る
          a(:href="`/shops/items/category/23081410034/?order=popular&stock=instock`")
            .item
              .image
                img(src="https://cdn.taberutokurasuto.com/image/events/okome/breed_happyhill.png" alt="ハッピーヒル")
              .info
                p.title ハッピーヒル
                p.text 小粒で丸みがあり、粘りは控えめ。あっさりした味わい。柔らかく、口に入れると甘みが広がる、
                .link
                  a(:href="`/shops/items/category/23081410034/?order=popular&stock=instock`") 商品を見る
          a(:href="`/shops/items/category/23081410037/?order=popular&stock=instock`")
            .item
              .image
                img(src="https://cdn.taberutokurasuto.com/image/events/okome/breed_indicarice.png" alt="インディカ米")
              .info
                p.title インディカ米
                p.text 珍しい国産の長粒米。炊き上がりの芳しい日本米にはない香りの良さ。日本米とブレンドしても美味しい。
                .link
                  a(:href="`/shops/items/category/23081410037/?order=popular&stock=instock`") 商品を見る
          a(:href="`/shops/items/category/23090810038/?order=popular&stock=instock`")
            .item
              .image
                img(src="https://cdn.taberutokurasuto.com/image/events/okome/others.png" alt="その他希少な米")
              .info
                p.title その他希少な米
                p.text 珍しい品種や、大切に守ってきた地域米、古くからある原種に近い品種など取り揃えています。
                .link
                  a(:href="`/shops/items/category/23090810038/?order=popular&stock=instock`") 商品を見る
          //- a(:href="`/shops/items/category/23081410031/?order=popular&stock=instock`")
          //-   .item
          //-     .image
          //-       img(src="https://cdn.taberutokurasuto.com/image/events/okome/breed_morinokumasan.png" alt="森のくまさん")
          //-     .info
          //-       p.title 森のくまさん
          //-       p.text 見た目はやや細い。甘味や風味が良い。弾力がしっかりとあり、もちもち粘りが強い。
          //-       .link
          //-         a(:href="`/shops/items/category/23081410031/?order=popular&stock=instock`") 商品を見る
          //- a(:href="`/shops/items/category/23081410036/?order=popular&stock=instock`")
          //-   .item
          //-     .image
          //-       img(src="https://cdn.taberutokurasuto.com/image/events/okome/breed_akitakomachi.png" alt="あきたこまち")
          //-     .info
          //-       p.title あきたこまち
          //-       p.text やや小粒。甘み、旨み、味、香りのバランスが良く食べやすい。甘く柔らかい食感。
          //-       .link
          //-         a(:href="`/shops/items/category/23081410036/?order=popular&stock=instock`") 商品を見る
          //- a(:href="`/shops/items/category/23081410036/?order=popular&stock=instock`")
          //-   .item
          //-     .image
          //-       img(src="https://cdn.taberutokurasuto.com/image/events/okome/breed_akitakomachi.png" alt="あきたこまち")
          //-     .info
          //-       p.title あきたこまち
          //-       p.text やや小粒。甘み、旨み、味、香りのバランスが良く食べやすい。甘く柔らかい食感。
          //-       .link
          //-         a(:href="`/shops/items/category/23081410036/?order=popular&stock=instock`") 商品を見る

    .banner(id="banner")
      h2 今すぐ届くお米
      a(href="https://taberutokurasuto.com/shops/items/tag/240902121632250/")
        img(src="https://cdn.taberutokurasuto.com/image/events/okome/shinmai_banner.jpg" alt="今すぐ届くお米")
    .side(id="side" v-if=("itemGroupsGohannotomo.length > 0"))
      h2 ご飯のお供
      ItemCardList
        .item.lg(v-for="(itemGroup, index) in itemGroupsGohannotomo" :key="itemGroup.id")
          ItemCard(:itemGroup="itemGroup")
      .button-block
        ButtonBase(:href="`/shops/items/category/100/`") ご飯のお供をすべて見る
</template>

<script>
  import { GetItemGroupsForApi } from "../../graphqls/ItemGroups"
  import GlobalMixin from "../../mixin/GlobalMixin"
  import ItemListMixin from "../../mixin/ItemListMixin"
  import ContainerSheet from "../../components/v1/container/ContainerSheet"
  import ButtonBase from "../../components/v1/button/ButtonBase"
  import ItemCard from "../../components/v1/item/ItemCardForApi"
  import ItemCardList from "../../components/v1/item/ItemCardList"

  export default {
    name: "event-okome",
    mixins: [GlobalMixin, ItemListMixin],
    components: {
      ContainerSheet,
      ButtonBase,
      ItemCard,
      ItemCardList,
    },
    data() {
      return {
        itemGroupsKoshihikari: [],
        itemGroupsHinohikari: [],
        itemGroupsSasanishiki: [],
        itemGroupsGohannotomo: [],
        itemGroups: [],
        stockFilter: 'instock',
        withinYearItem: this.$route.query.wiy === 'true',
      }
    },
    metaInfo() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    mounted () {
      // サイド表示
      this.showSide()
      // 商品データ取得
      this.fetchItemGroupsKoshihikari()
      this.fetchItemGroupsHinohikari()
      this.fetchItemGroupsSasanishiki()
      this.fetchItemGroupsGohannotomo()
      // パンくずリストを設定
      this.setBreadcrumbs({ breadcrumbs: [
        {
          path: '/',
          name: 'TOP',
        },
        {
          path: '/event/okome/',
          name: 'お米特集',
        },
      ]})
    },
    methods: {
      async fetchItemGroupsKoshihikari() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetItemGroupsForApi,
          variables: {
            // コシヒカリのカテゴリーコード
            categoryCode: "23081410027",
            stockFilter: this.stockFilter,
            withinYearItem: this.withinYearItem,
            perPage: 3,
            orderBy: 'random'
          },
          client: 'apiClient'
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.itemGroupsKoshihikari = data.itemGroups.items;
        this.loadingStop();
      },
      async fetchItemGroupsHinohikari() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetItemGroupsForApi,
          variables: {
            // ヒノヒカリのカテゴリーコード
            categoryCode: "23081410028",
            stockFilter: this.stockFilter,
            withinYearItem: this.withinYearItem,
            perPage: 3,
            orderBy: 'random'
          },
          client: 'apiClient'
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.itemGroupsHinohikari = data.itemGroups.items;
        this.loadingStop();
      },
      async fetchItemGroupsSasanishiki() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetItemGroupsForApi,
          variables: {
            // ササニシキのカテゴリーコード
            categoryCode: "23081410035",
            stockFilter: this.stockFilter,
            withinYearItem: this.withinYearItem,
            perPage: 3,
            orderBy: 'random'
          },
          client: 'apiClient'
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.itemGroupsSasanishiki = data.itemGroups.items;
        this.loadingStop();
      },
      async fetchItemGroupsGohannotomo() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetItemGroupsForApi,
          variables: {
            // ご飯のお供のカテゴリーコード
            categoryCode: "100",
            stockFilter: this.stockFilter,
            withinYearItem: this.withinYearItem,
            perPage: 3,
            orderBy: 'popular'
          },
          client: 'apiClient'
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.itemGroupsGohannotomo = data.itemGroups.items;
        this.loadingStop();
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";

.container {
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
  font-style: $font-style-not-italic;
  font-size: 14px;
  line-height: $line-height-normal;
  color: $black-400;
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  .mv_okome {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    height: auto;
  }
  .item-card-list {
    width: 100%;
    text-align: left;
  }
  .okome_heading_bg {
    background-image: url(https://cdn.taberutokurasuto.com/image/events/okome/okome_heading_bg.svg);
    
    background-position: center center;
    height: 52px;
    margin: 0 auto 16px;
    width: 320px;
  }
  h2 {
    font-size: 20px;
    font-family: $font-family-serif;
    font-style: normal;
    font-weight: 400;
    padding: 16px 0px;
    position: relative;
  }
  h2::after {
    content: '';
    background-color: $olive-300;
    width: 2em;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
  }
  h3 {
    font-family: $font-family-serif;
    font-weight: $font-weight-regular;
    font-size: 18px;
  }
  > .top,
    .coupon,
    .chart,
    .breed,
    .kind,
    .banner,
    .side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 32px;
      align-self: stretch;
      text-align: center;
      padding-top: 64px;
  }
  > .top {
    font-family: $font-family-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    >  .text-center {
      span {
        @include font-size(sm);
      }
      > .lead {
        @include font-size(sm);
        max-width: 560px;
        text-align: left;
      }
    }
    .index {
      padding: 16px;
      background-color: $ivory-100;
      margin: auto;
      width: 100%;
      max-width: 480px;
      > .title {
        font-size: 18px;
        border-bottom: 1px solid $black-400;
        margin-bottom: 8px;
        text-align: left;
      }
      > .links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        > a {
            color: $olive-400;
            text-decoration: none;
            font-family: $font-family-sans;
        }
      }
    }
  }
  > .coupon {
    padding: auto;
    > img {
      width: 100%;
      max-width: 480px;
      height: auto;
      margin: auto;
    }
  }
  > .chart {
    padding: auto;
    > img {
      width: 100%;
      max-width: 720px;
      height: auto;
      margin: auto;
    }
  }
  > .banner {
    padding: auto;
    > a {
      > img {
        width: 100%;
        max-width: 720px;
        height: auto;
        margin: auto;
      }
    }
  }
  > .breed {
    > .breed-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      padding-top: 24px;
      > p {
        padding: 8px 0;
        width: 100%;
        max-width: 480px;
        text-align: left;
      }
    }
  }
  > .breed,
    .kind {
    > .other {
      > .column {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        > a {
          color: $black-400;
          text-decoration: none;
          font-family: $font-family-sans;
          > .item {
            display: flex;
            height: 100%;
            padding: 12px;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            border: 3px solid $ivory-100;
            > .image {
              width: 64px;
              height: 64px;
              > img {
                width: 100%;
                height: 100%;
              }
            }
            > .info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              flex: 1 0 0;
              gap: 4px;
              > .text {
                line-height: $line-height-tight;
                text-align: left;
              }
              > .title {
                font-family: $font-family-serif;
                font-size: 18px;
              }
              > .link {
                width: 100%;
                text-align: right;
                > a {
                  color: $olive-400;
                  
                }
              }
            }
          }
        }
      }
      @media (max-width: 768px) {
        > .column {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    }
  }
}
</style>