<template lang="pug">
  .the-sidemenu
    SideMenuShop(v-if="!this.isHeaderMenu")

    template(v-if="showFathersDayBanner")
      a(@click="$_gaSendEvent('特設ページへ遷移', 'サイドナビバナー', '父の日特集')" href="/gifts/fathersday/")
        img.banner(alt="父の日特集" src="https://cdn.taberutokurasuto.com/image/common/banner/fathersday.png")
    a(@click="$_gaSendEvent('特設ページへ遷移', 'サイドナビバナー', '法人ギフト一括注文')" href="/shop/page/23110611082/")
      img.banner(alt="法人ギフト一括注文" src="https://cdn.taberutokurasuto.com/image/common/banner/houjin.jpg")
    a(@click="$_gaSendEvent('特設ページへ遷移', 'サイドナビバナー', '内祝い・お返し')" href="/gifts/uchiiwai/")
      img.banner(alt="内祝い" src="https://cdn.taberutokurasuto.com/image/common/banner/uchiiwai.png")

    SideMenuNav(title="一覧から探す")
      ul
        li
          a(href="/shops/items/popular/" @click="$_gaSendEvent('商品一覧へ遷移', 'サイドナビ', '人気商品')") 人気商品
        li
          a(href="/shops/items/new/" @click="$_gaSendEvent('商品一覧へ遷移', 'サイドナビ', '新着・再販売商品')") 新着・再販売商品
        li
          a(href="/shops/items/gift/" @click="$_gaSendEvent('商品一覧へ遷移', 'サイドナビ', 'ギフト対応商品')") ギフト対応商品
        li
          a(href="/shops/items/onetime/" @click="$_gaSendEvent('商品一覧へ遷移', 'サイドナビ', '初回限定商品')") 初回限定商品
        li
          a(href="/shops/items/freeshipping/" @click="$_gaSendEvent('商品一覧へ遷移', 'サイドナビ', '送料無料商品')") 送料無料商品
        li
          a(href="/shops/items/compact/" @click="$_gaSendEvent('商品一覧へ遷移', 'サイドナビ', 'メール便対応商品')") メール便対応商品
        li
          a(href="/shops/items/forbusiness/" @click="$_gaSendEvent('商品一覧へ遷移', 'サイドナビ', '業務用仕入まとめ買い')") 業務用仕入まとめ買い
        li
          a(href="/shops/items/subscription/" @click="$_gaSendEvent('商品一覧へ遷移', 'サイドナビ', '定期購入商品')") 定期購入商品
        li
          a(href="/shop/favorite/" @click="$_gaSendEvent('商品一覧へ遷移', 'サイドナビ', 'お気に入り商品')") お気に入り商品

    SideMenuNav(title="全体カテゴリから探す")
      ul
        li(v-for="itemCategory in hasItemRootItemCategories.items" :key="itemCategory.id")
          a(:href="`/shops/items/category/${itemCategory.code}/`" @click="$_gaSendEvent('商品一覧へ遷移', 'サイドナビ', itemCategory.name)") {{itemCategory.name}}

    SideMenuNav(title="シーンで探す")
      ul
        li(v-for="itemTag in itemTags.items" :key="itemTag.id")
          a(:href="`/shops/items/tag/${itemTag.code}/`" @click="$_gaSendEvent('商品一覧へ遷移', 'サイドナビ', itemTag.name)") {{itemTag.name}}

    SideMenuNav(title="生産者から探す")
      p.subtitle 新着順
      ul
        li(v-for="adminShop in adminShops.items" :key="adminShop.id")
          a(:href="`/shop/${adminShop.code}/`" @click="$_gaSendEvent('ショップトップへ遷移', 'サイドナビ', adminShop.name)") {{adminShop.name}}
      p.text-right
        a(href="/shops/" @click="$_gaSendEvent('ショップ一覧へ遷移', 'サイドナビ', '生産者一覧')")
          | 生産者一覧を見る
          IconArrowRight(:size="12")

    SideMenuNav(title="生産地から探す")
      ul
        li(v-for="shippingRegion in shippingRegions" :key="shippingRegion.code")
          a(:href="`/shops/items/productionarea/${shippingRegion.code}/`" @click="$_gaSendEvent('商品一覧へ遷移', 'サイドナビ', shippingRegion.name)") {{shippingRegion.name}}

    SideMenuNav(title="「たべるとくらすと」とは？")
      p 真面目に丁寧に「いいものを作っています！」というこだわり生産者の直売モールです。食べる暮らしをゆたかにする。をテーマに無添加や無農薬といった安心で美味しい食品生産者の直売所です。
      p.text-right
        a(href="/about/" @click="$_gaSendEvent('「たべるとくらすと」とは？へ遷移', 'サイドナビ', '「たべるとくらすと」とは？')")
          | 詳しくはこちら
          IconArrowRight(:size="12")

    SideMenuNav(title="生産者の方へ")
      p たべるとくらすとでは、無添加食品や無農薬農産品の生産者さんを募集しています。
      p.text-right
        a(href="/opencontacts/" @click="$_gaSendEvent('出店に関するお問い合わせへ遷移', 'サイドナビ', '生産者の方へ')")
          | 詳しくはこちら
          IconArrowRight(:size="12")

    SideMenuNav(title="読みもの")
      ul
        li
          a(href="/columns/report/" @click="$_gaSendEvent('スタッフレポートへ遷移', 'サイドナビ', 'ごちそうさま日記')")
            IconReport(:size="14")
            | ごちそうさま日記
        li
          a(href="/notes/" @click="$_gaSendEvent('食材ノートへ遷移', 'サイドナビ', '食材ノート')")
            IconNote(:size="14")
            | 食材ノート
        li
          a(href="/columns/gohan/" @click="$_gaSendEvent('今日のごはんへ遷移', 'サイドナビ', '今日のごはん')")
            IconRice(:size="14")
            | 今日のごはん

    SideMenuNav(title="お買い物について")
      ul
        li
          a(href="https://essnu.notion.site/75acf162c73c4f86baf89697d51985ea" target="_blank" @click="$_gaSendEvent('よくある質問へ遷移', 'サイドナビ', 'よくあるご質問')")
            IconQuestion(:size="14")
            | よくあるご質問
        li
          a(href="/regist/" @click="$_gaSendEvent('会員登録へ遷移', 'サイドナビ', '会員登録')")
            IconSignin(:size="14")
            | 会員登録
        li
          a(href="/login/" @click="$_gaSendEvent('ログインへ遷移', 'サイドナビ', 'ログイン')")
            IconLogin(:size="14")
            | ログイン
        li
          a(href="/shop/cart/" @click="$_gaSendEvent('カートへ遷移', 'サイドナビ', 'ショッピングカート')")
            IconCart(:size="14")
            | ショッピングカート
        li
          a(href="/contacts/" @click="$_gaSendEvent('お問い合せへ遷移', 'サイドナビ', 'サイトへのお問合せ')")
            IconMailline(:size="14")
            | サイトへのお問合せ
</template>

<script>
  import { mapActions } from 'vuex'
  import GlobalMixin from "../../../mixin/GlobalMixin"
  import { GetHasItemRootItemCategories } from "../../../graphqls/ItemCategories"
  import {GetItemTags} from "../../../graphqls/ItemTags"
  import {GetAdminShops} from "../../../graphqls/AdminShops"
  import SideMenuShop from './SideMenuShop'
  import SideMenuNav from './SideMenuNav'
  import IconArrowRight from '../icon/IconArrowRight'
  import IconReport from '../icon/IconReport'
  import IconNote from '../icon/IconNote'
  import IconRice from '../icon/IconRice'
  import IconQuestion from '../icon/IconQuestion'
  import IconSignin from '../icon/IconSignin'
  import IconCart from '../icon/IconCart'
  import IconLogin from '../icon/IconLogin'
  import IconMailline from '../icon/IconMailline'

  export default {
    name: 'TheSideMenu',
    mixins: [GlobalMixin],
    components: {
      SideMenuShop,
      SideMenuNav,
      IconArrowRight,
      IconReport,
      IconNote,
      IconRice,
      IconQuestion,
      IconSignin,
      IconCart,
      IconLogin,
      IconMailline,
    },
    props: {
      currentShopId: null,
      isHeaderMenu: false,
    },
    data() {
      return {
        hasItemRootItemCategories: [],
        itemTags: [],
        adminShops: {
          items: []
        }
      }
    },
    computed: {
      shippingRegions() {
        return [
          {code: '01', name: '北海道'},
          {code: '02', name: '北東北'},
          {code: '03', name: '南東北'},
          {code: '04', name: '関東'},
          {code: '05', name: '信越'},
          {code: '06', name: '東海'},
          {code: '07', name: '北陸'},
          {code: '08', name: '関西'},
          {code: '09', name: '中国'},
          {code: '10', name: '四国'},
          {code: '11', name: '九州'},
          {code: '12', name: '沖縄'}
        ]
      },
      showFathersDayBanner() {
        return false
        // let fathersDay = new Date('2022-6-20'); // 父の日の翌日を設定
        // return new Date() < fathersDay
      }
    },
    methods: {
      async fetchHasItemRootItemCategories() {
        const { data } = await this.$apollo
            .query({
              query: GetHasItemRootItemCategories,
              variables: {
                perPage: -1
              },
              client: 'apiClient'
            })
            .catch(error => {
              return;
            });
        this.hasItemRootItemCategories = data.hasItemRootItemCategories;
      },
      async fetchItemTags() {
        const { data } = await this.$apollo
            .query({
              query: GetItemTags,
              variables: {
                perPage: 20,
                search: JSON.stringify({show_menu_status_eq: 'show_menu'})
              },
              client: 'apiClient'
            })
            .catch(error => {
              return;
            });
        this.itemTags = data.itemTags;
      },
      async fetchNewAdminShops() {
        const { data } = await this.$apollo
            .query({
              query: GetAdminShops,
              variables: {
                perPage: 20,
                orderBy: 'newly_at desc'
              },
              client: 'apiClient'
            })
            .catch(error => {
              return;
            });
        this.adminShops = data.adminShops;
      },
      ...mapActions([
        'fetchCurrentShop'
      ])
    },
    mounted: function () {
      if (this.currentShopId && !this.isHeaderMenu) {
        this.fetchCurrentShop({
          shopId: this.currentShopId
        })
      }
      this.fetchHasItemRootItemCategories()
      this.fetchItemTags()
      this.fetchNewAdminShops()
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .the-sidemenu {
    > .sidemenu-shop {
      margin-bottom: #{map-get($padding-margin-pc, xxxs)}px;
    }

    > a > .banner {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: #{map-get($padding-margin-pc, xxxs)}px;
    }

    > .sidemenu-nav {
      margin-bottom: #{map-get($padding-margin-pc, xxxs)}px;
    }
  }
</style>

<style lang="scss">
  @import "../../../../../assets/stylesheets/v1/variables";
  @import "../../../../../assets/stylesheets/v1/mixins";
  #shop .side .the-sidemenu .sidemenu-shop {
    // margin-top: -120px;
    @include mq-down(md) {
      margin-top: 0;
    }
  }
</style>
